import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: "Hugh's Portfolio",
  lang: 'en',
  description: 'Welcome to my portfolio',
};

// HERO DATA
export const heroData = {
  title: "Hi, I'm",
  name: 'Hugh',
  subtitle: "I'm a Software Engineer",
  cta: 'About me',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    "Hi, I'm Hugh. I'm a software engineer at SafetyCulture. I primarily specialise in the frontend, working mostly in Typescript + React.",
  paragraphTwo:
    "I also spend a bit of time in the backend in Node and Go. Currently I'm working on expanding my skills in Go and Python.",
  paragraphThree:
    'My side projects are hacky solutions for problems I face in the real world, which includes a strong interest in finance and investing :).',
};

// PROJECTS DATA
export const projectsDataWithImgs = [
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'Custom Domain Property Search',
    info:
      "This project was a simple implementation using Domain Property's API. I had very specific types of properties I was looking at and I was not satisfied with the filtering and options available on Domain's website.",
    info2:
      'This web app fetches property listings against a certain criteria and does some additional filtering, before presenting the properties in a simple custom list view.',
    linkName: '',
    url: 'https://sydneypropertysearch.netlify.app/',
    repoName: 'Github',
    repo: 'https://github.com/HughG-50/property-search-frontend',
  },
  {
    id: nanoid(),
    img: 'project2.jpg',
    title: 'Cryptocurrency portfolio tracker',
    info: 'Simple web app to show prices of cryptocurrencies in a portfolio.',
    info2: '',
    linkName: '',
    url: 'https://cryptocurrency-portfolio.netlify.app/',
    repoName: 'Github',
    repo: 'https://github.com/HughG-50/cryptocurrency-portfolio-frontend',
  },
];

export const projectsData = [
  [
    {
      id: nanoid(),
      title: 'Messenger reminder bot',
      info:
        'A simple hack solution to allow automated messages to be sent in Facebook Messenger. The selenium script simulates signing in as a user, then manually posts a message, which can be run on a schedule.',
      info2: '',
      linkName: 'Github',
      url: 'https://github.com/HughG-50/messenger-reminder-bot',
      repoName: '',
      repo: '',
    },
    {
      id: nanoid(),
      title: 'Domain property automation',
      info:
        'This project makes use of webscraping to get sold price data of recently sold properties in Australia. Enter the target suburb and it will produce a csv of the prices of the most recent sales.',
      info2: 'Very handy for investors looking to do some research!',
      linkName: 'Github',
      url: 'https://github.com/HughG-50/domain-property-automation',
      repoName: '',
      repo: '',
    },
  ],
];

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: '',
    },
    {
      id: nanoid(),
      name: 'github',
      url: '',
    },
  ],
};
